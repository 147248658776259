import React, { useState, useEffect } from 'react';
import Layout from "../../components/public/layout"
import { Helmet } from "react-helmet"
import SearchBar from '../../components/public/searchBar'
import axios from 'axios'
import tempGravePhoto from '../../images/grave-without-image.svg'
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross } from '@fortawesome/free-solid-svg-icons'
const Szukaj = () => {
    const [graves, setGraves] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [query, setQuery] = useState('')
    const formatDate = (date) => {
        let tempDate = new Date(date)
        if (tempDate.getDate() === 1 && tempDate.getMonth() === 0)
            return tempDate.toLocaleDateString('pl-PL', { year: 'numeric' })
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }
    const checkDate = (date) => {
        let tempDate = new Date(date)
        let treshDate = new Date(2022, 0, 1)
        return tempDate >= treshDate
    }
    const getData = async () => {
        const params = new URLSearchParams(window.location.search);
        await axios.get(
            `${process.env.GATSBY_API_URL}:8080/api/v1/noauth/grave/query/fn/${params.get("firstName")}/ln/${params.get("lastName")}/dd/${params.get("deathDate")}/bd/${params.get("birthDate")}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setGraves(response.data)
            setLoaded(true)
            let tempQuery = ''
            tempQuery += params.get("firstName") !== 'null' ? `${params.get("firstName")} ` : ''
            tempQuery += params.get("lastName") !== 'null' ? `${params.get("lastName")} ` : ''
            tempQuery += params.get("birthDate") !== 'null' ? `Rok urodzenia ${params.get("birthDate")} ` : ''
            tempQuery += params.get("deathDate") !== 'null' ? `Rok śmierci ${params.get("deathDate")} ` : ''
            setQuery(tempQuery)
        })
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <Layout>
            <Helmet title={`Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="left-col">
                    <div className="search-results">
                        <span className='where' style={{ padding: 10, marginBottom: 20 }}>
                            <Link to='/'>Strona Główna</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/cmentarz'>Cmentarz</Link>
                        </span>
                        {loaded ?
                            <>
                                <h2>Wyszukiwanie:</h2>
                                <p style={{ fontSize: 18 }}>Dla hasła: {query}</p>
                                {
                                    graves.length === 0 ?
                                        <div style={{ marginTop: '1em' }}>Brak wyników</div>
                                        : null
                                }
                                {
                                    graves === null ? typeof window !== undefined ? window.location.reload(true) : null :
                                        graves.filter(grave => grave.images.length > 0 || (grave.people.length > 0 && checkDate(grave.people[0].deathDate))).map(grave => {
                                            return (
                                                <Link to={`/cmentarz/grob/${grave.id}`}>
                                                    <div className="grave">
                                                        {
                                                            grave.images.length === 0 ?
                                                                <img src={tempGravePhoto} alt='' width='35%' height='480px' /> :
                                                                <img src={`${process.env.GATSBY_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[0].name}`} alt='' width='35%' height='480px' />
                                                        }
                                                        <div className='people'>
                                                            <h3>
                                                                Kwatera: <strong>{grave.sectionName}</strong><br />
                                                                Rząd: <strong>{grave.row}</strong> Pozycja: <strong>{grave.position}</strong>
                                                                <br />
                                                                <br />
                                                                <span style={{ fontSize: 80 }}>
                                                                    <strong><FontAwesomeIcon icon={faCross} /></strong>
                                                                </span>
                                                                <br /><br />
                                                                <strong style={{ fontSize: 32 }}>Ś.P.</strong>
                                                            </h3>
                                                            {
                                                                grave.people.map(person => {
                                                                    return (
                                                                        <div className="person">
                                                                            <strong>{person.firstName} {person.lastName}</strong><br />
                                                                            zm. {person.deathDate === null ? <>b/d</> : `${formatDate(person.deathDate)} r.`}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                }
                            </> : <h2>Wyszukiwanie ...</h2>}
                    </div>
                </div>
                <div className="right-col">
                    <SearchBar />
                </div>
            </div>


        </Layout>
    );
}

export default Szukaj;
